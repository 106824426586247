import { FC, HTMLProps } from "react";
import classNames from "clsx";
import React from "react";

type InputProps = {
  label?: string;
} & HTMLProps<HTMLInputElement>

const Input: FC<InputProps> = ({
  id,
  className,
  disabled,
  label,
  required,
  ...rest
}) => {
  return (
    <div className={classNames("flex flex-col w-full", className)}>
      {!!label && (
        <label htmlFor={id} className="mb-1 px-4 color-white">
          {label}
          {' '}
          {required && <span className="text-red-600">*</span>}
        </label>
      )}
      <input
        className={classNames(
          "w-full",
          "py-3 px-4",
          "box-border",
          "text-brand-light leading-none",
          "bg-black/20",
          "border border-brand-tertiary/10",
          "rounded-[.625rem]",
          "outline-none",
          !disabled && "cursor-pointer focus:cursor-text",
          "transition-colors",
          "placeholder-brand-placeholder",
          !disabled && [
            "hover:bg-brand-tertiary/5",
            "hover:border-brand-tertiary/5",
          ],
          "focus:bg-black/20 focus:border-brand-tertiary/[.15]",
          disabled && [
            "bg-brand-disabledbackground/20",
            "border-brand-tertiary/5",
            "text-brand-disabledcolor placeholder-brand-disabledcolor",
          ],
          "transition ease-in-out duration-200"
        )}
        disabled={disabled}
        required={required}
        id={id}
        {...rest}
      />
    </div>
  );
};

export default Input;
