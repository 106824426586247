import React from "react";

import { ReactComponent as MailIcon } from "../icons/mail.svg";
import SocialLinks from "./SocialLinks";
import SubscriptionForm from "./SubscriptionForm";
import CommonSection from "./CommonSection";

const Newsletter = () => {
  return (
    <CommonSection id="follow" className="!pb-0">
      <div
        className="relative bg-brand-tertiary/5 rounded-[1.875rem] py-8 px-4 lg:px-8 md:py-13 md:px-10"
        data-aos="fade-up"
      >
        <div className="relative flex flex-col lg:flex-row justify-between">
          {/* CTA content */}
          <div className=" px-2 lg:px-0 mb-6 lg:pr-16 lg:mb-0 text-center lg:text-left lg:w-1/2 lg:py-2">
            <h3 className="text-[2rem] font-bold text-white mb-2">
              Follow our updates
            </h3>
            <p className="text-brand-secondary text-base">
              We want to develop the most convenient and useful wallet for the
              web3 community. However, it will be impossible to do without
              your propositions and feedback. Join our communities and help us
              to create the Web3 Wallet of the future.
            </p>
          </div>

          {/* CTA form */}
          <div className="w-full lg:w-1/2 pt-6 lg:pt-0 lg:pl-10 lg:border-l lg:border-t-0 border-t border-brand-tertiary/[.07] px-2 lg:px-0 lg:py-2">
            <div className="flex items-center">
              <h4 className="sm:text-lg font-bold text-white">Our communities:</h4>
              <SocialLinks className="ml-3" />
            </div>
            <div className="flex flex-col mt-4 mb-5">
              <h4 className="sm:text-lg font-bold text-white mb-1">
                You may always write us on email:
              </h4>
              <a
                href="mailto:info@wigwam.app"
                className="!flex sm:text-lg text-brand-secondary"
              >
                <MailIcon className="mr-2 w-6 h-auto" />
                info@wigwam.app
              </a>
            </div>
            <SubscriptionForm className="ml-0 lg:ml-auto" />
          </div>
        </div>
      </div>
    </CommonSection>
  );
};

export default Newsletter;
