import React, { FC, ReactNode } from "react";
import classNames from "clsx";

type CommonSectionProps = {
  id?: string;
  title?: ReactNode;
  description?: ReactNode;
  isAbout?: boolean;
  className?: string;
};

const CommonSection: FC<CommonSectionProps> = ({
  isAbout,
  id,
  title,
  description,
  className,
  children,
}) => (
  <section id={id} className="relative">
    {isAbout && (
      <div aria-hidden="true" className="z-0 absolute top-0 right-0 w-[100vw] h-full">
        <div className="hidden md:block w-[20rem] h-[20rem] rounded-full absolute left-0 top-[8rem] bg-gradient-to-r from-brand-accenttwo to-brand-accentone" />
        <div className="w-[11rem] h-[11rem] rounded-full absolute right-[5vw] bottom-0 bg-gradient-to-r from-brand-accenttwo to-brand-accentone" />
        <div className="rotate-0 absolute w-[130vw] h-[120%] rounded-[6.25rem] bottom-[5rem] right-0 md:right-[8vw] bg-[#07081B]/[.05] border border-brand-tertiary/[.15] backdrop-blur-2xl" />
      </div>
    )}
    <div
      className={classNames(
        "relative max-w-6xl mx-auto px-4 py-12 sm:px-6 sm:py-20",
        className
      )}
    >
      {!!title && (
        <div className="max-w-3xl mx-auto text-center pb-8 md:pb-14">
          <h2 className="h2 mb-4" data-aos="fade-up">
            {title}
          </h2>
          {!!description && (
            <p
              className="text-lg sm:text-xl text-brand-secondary"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              {description}
            </p>
          )}
        </div>
      )}
      {children}
    </div>
  </section>
);

export default CommonSection;
