import React, { FC, useCallback, useRef, FormEvent } from "react";
import classNames from "clsx";

import swal from "sweetalert";
import addToMailchimp from "gatsby-plugin-mailchimp";

import Input from "./Input";
import NewButton from "./NewButton";

const SubscriptionForm: FC<{ className?: string }> = ({ className }) => {
  const processingRef = useRef(false);

  const handleSubmit = useCallback(async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    if (processingRef.current) return;
    processingRef.current = true;

    try {
      const formEl = evt.target as HTMLFormElement;
      const formData = new FormData(formEl);
      const email = formData.get("email");

      let { result, msg } = await addToMailchimp(email, {
        EARLY_BIRD: "true",
      });

      if (result === "error") {
        if (msg.includes("already subscribed")) {
          msg = "Thank you for subscribing!";
        } else {
          throw new Error(msg);
        }
      }

      swal("Success!", msg, "success");

      formEl.reset();
    } catch (err: any) {
      console.error(err);
      swal(
        "Oops!",
        `Failed to subscribe to newsletter.\nReason: ${err?.message}`,
        "error"
      );
    }

    processingRef.current = false;
  }, []);

  return (
    <div
      className={classNames(
        "flex flex-col items-start max-w-[35rem] w-full mx-auto",
        className
      )}
      data-aos="fade-up"
      data-aos-delay="300"
    >
      <h4 className="sm:text-lg text-left sm:text-center font-semibold text-brand-primary/90 mb-3">
        Become one of the first beta users, earn rewards:
      </h4>
      <form
        name="subscribe"
        className={classNames("flex flex-col w-full", "sm:flex-row", className)}
        onSubmit={handleSubmit}
      >
        <Input
          name="email"
          type="email"
          placeholder="Leave an Email"
          required
        />
        <NewButton type="submit" className="mt-4 sm:ml-4 sm:mt-0">
          Join
        </NewButton>
      </form>
    </div>
  );
};

export default SubscriptionForm;
